import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import qs from "qs";
import { googleAppUrl } from "configs/constants";
import axios from "axios";
import get from "lodash/get";
import { QuestionsFormSchema } from "schemes";
import classnames from "classnames";
import styles from "./styles";

const initialValues = {
  email: "",
  message: "",
  phone: ""
};
const googleFormProps = {
  formDataNameOrder: JSON.stringify(["email", "phone", "message"]),
  formGoogleSheetName: "Question Form on Title",
  formName: "Question Form on Title"
};

const CommonForm = ({ classes, page }) => {
  const whiteInput = classnames(classes.input, classes.inputWhite);
  const grayInput = classnames(classes.input, classes.inputGray);

  const currentPage =
    page === "main"
      ? {
          inputColor: whiteInput,
          textPhone: "Phone *",
          textBig: "Message *"
        }
      : {
          inputColor: grayInput,
          textPhone: "Phone with country code *",
          textBig: "Description *"
        };

  const textFieldProps = {
    autoComplete: "off"
  };

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = React.useCallback(
    async (values, { setSubmitting, resetForm, setStatus }) => {
      try {
        setSubmitting(true);

        const options = {
          data: qs.stringify({
            ...values,
            ...googleFormProps
          }),
          headers: { "content-type": "application/x-www-form-urlencoded" },
          method: "post",
          url: googleAppUrl
        };
        const response = await axios(options);
        const { data } = response;

        if (data.result === "success") {
          enqueueSnackbar("Your message has been sent. Thank you!", {
            variant: "success"
          });
        } else if (data.result === "error") {
          const errorMessage = get(data, "error.message", "Unexpected error");

          enqueueSnackbar(errorMessage, { variant: "error" });
        }
        setStatus({ success: true });
        resetForm();
      } catch (err) {
        enqueueSnackbar(err, {
          variant: "error"
        });
        setStatus({ success: false });
      } finally {
        setSubmitting(false);
      }
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={QuestionsFormSchema}
      render={props => {
        const {
          handleSubmit,
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          isSubmitting
        } = props;

        const hasmessageError = touched.message && Boolean(errors.message);
        const hasEmailError = touched.email && Boolean(errors.email);
        const hasPhoneError = touched.phone && Boolean(errors.phone);
        const hasFormError = hasmessageError || hasEmailError || hasPhoneError;

        return (
          <Form
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Grid className={classes.container} container justify="center">
              <Grid>
                <Grid container>
                  <Grid className={classes.row}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      InputProps={textFieldProps}
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email *"
                      error={hasEmailError}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      helperText={errors.email}
                      className={currentPage.inputColor}
                    />
                  </Grid>

                  <Grid className={classes.row}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      InputProps={textFieldProps}
                      id="phone"
                      name="phone"
                      type="phone"
                      placeholder={currentPage.textPhone}
                      error={hasPhoneError}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      helperText={errors.phone}
                      className={currentPage.inputColor}
                    />
                  </Grid>
                  <Grid className={classes.row}>
                    <TextField
                      variant="outlined"
                      multiline
                      required
                      rows="6"
                      fullWidth
                      InputProps={textFieldProps}
                      id="message"
                      name="message"
                      placeholder={currentPage.textBig}
                      error={hasmessageError}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      helperText={errors.message}
                      className={currentPage.inputColor}
                    />
                  </Grid>
                </Grid>

                <div className={classes.buttonContainer}>
                  <Button
                    margin="normal"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.button}
                    disabled={isSubmitting || hasFormError}
                  >
                    Send
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    />
  );
};

CommonForm.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    buttonContainer: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    sectionDescription: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    inputWhite: PropTypes.string.isRequired,
    inputGray: PropTypes.string.isRequired,
    row: PropTypes.string.isRequired,
    terms: PropTypes.string.isRequired
  }).isRequired,
  errors: PropTypes.shape({
    email: PropTypes.bool.isRequired,
    message: PropTypes.bool.isRequired,
    phone: PropTypes.bool.isRequired
  }),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  touched: PropTypes.shape({
    email: PropTypes.bool.isRequired,
    message: PropTypes.bool.isRequired,
    phone: PropTypes.bool.isRequired
  }),
  values: PropTypes.shape({
    email: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired
  }),
  page: PropTypes.string.isRequired
};

CommonForm.defaultProps = {
  errors: {
    email: false,
    message: false,
    phone: false
  },
  handleBlur: () => {},
  handleChange: () => {},
  handleSubmit: () => {},
  isSubmitting: false,
  touched: {
    email: false,
    message: false,
    phone: false
  },
  values: initialValues
};

export default withStyles(styles, { withTheme: true })(CommonForm);
