exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-developers-api-chooser-allcase-js": () => import("./../../../src/pages/developers/api-chooser/allcase.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-allcase-js" */),
  "component---src-pages-developers-api-chooser-case-1-js": () => import("./../../../src/pages/developers/api-chooser/case1.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-case-1-js" */),
  "component---src-pages-developers-api-chooser-case-10-js": () => import("./../../../src/pages/developers/api-chooser/case10.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-case-10-js" */),
  "component---src-pages-developers-api-chooser-case-11-js": () => import("./../../../src/pages/developers/api-chooser/case11.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-case-11-js" */),
  "component---src-pages-developers-api-chooser-case-12-js": () => import("./../../../src/pages/developers/api-chooser/case12.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-case-12-js" */),
  "component---src-pages-developers-api-chooser-case-2-js": () => import("./../../../src/pages/developers/api-chooser/case2.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-case-2-js" */),
  "component---src-pages-developers-api-chooser-case-3-js": () => import("./../../../src/pages/developers/api-chooser/case3.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-case-3-js" */),
  "component---src-pages-developers-api-chooser-case-4-js": () => import("./../../../src/pages/developers/api-chooser/case4.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-case-4-js" */),
  "component---src-pages-developers-api-chooser-case-5-js": () => import("./../../../src/pages/developers/api-chooser/case5.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-case-5-js" */),
  "component---src-pages-developers-api-chooser-case-6-js": () => import("./../../../src/pages/developers/api-chooser/case6.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-case-6-js" */),
  "component---src-pages-developers-api-chooser-case-7-js": () => import("./../../../src/pages/developers/api-chooser/case7.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-case-7-js" */),
  "component---src-pages-developers-api-chooser-case-8-js": () => import("./../../../src/pages/developers/api-chooser/case8.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-case-8-js" */),
  "component---src-pages-developers-api-chooser-case-9-js": () => import("./../../../src/pages/developers/api-chooser/case9.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-case-9-js" */),
  "component---src-pages-developers-api-chooser-js": () => import("./../../../src/pages/developers/api-chooser.js" /* webpackChunkName: "component---src-pages-developers-api-chooser-js" */),
  "component---src-pages-developers-api-key-js": () => import("./../../../src/pages/developers/api-key.js" /* webpackChunkName: "component---src-pages-developers-api-key-js" */),
  "component---src-pages-developers-data-domain-js": () => import("./../../../src/pages/developers/data-domain.js" /* webpackChunkName: "component---src-pages-developers-data-domain-js" */),
  "component---src-pages-developers-dot-net-js": () => import("./../../../src/pages/developers/dot-net.js" /* webpackChunkName: "component---src-pages-developers-dot-net-js" */),
  "component---src-pages-developers-error-handling-js": () => import("./../../../src/pages/developers/error-handling.js" /* webpackChunkName: "component---src-pages-developers-error-handling-js" */),
  "component---src-pages-developers-index-js": () => import("./../../../src/pages/developers/index.js" /* webpackChunkName: "component---src-pages-developers-index-js" */),
  "component---src-pages-developers-java-js": () => import("./../../../src/pages/developers/java.js" /* webpackChunkName: "component---src-pages-developers-java-js" */),
  "component---src-pages-developers-json-rest-api-guidelines-js": () => import("./../../../src/pages/developers/json-rest-api-guidelines.js" /* webpackChunkName: "component---src-pages-developers-json-rest-api-guidelines-js" */),
  "component---src-pages-developers-make-sale-js": () => import("./../../../src/pages/developers/make-sale.js" /* webpackChunkName: "component---src-pages-developers-make-sale-js" */),
  "component---src-pages-developers-release-notes-index-js": () => import("./../../../src/pages/developers/release-notes/index.js" /* webpackChunkName: "component---src-pages-developers-release-notes-index-js" */),
  "component---src-pages-developers-sync-js": () => import("./../../../src/pages/developers/sync.js" /* webpackChunkName: "component---src-pages-developers-sync-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

