import { v1 as uuid } from 'uuid';

export default [
  {
    description:
      'POS vendors, POS developers, POS hardware manufacturers IT business automation companies: E-Commerce, Bookkeeping, Business intelligence, Warehousing, Staff Management, Booking, CRM, Finance, and more',
    id: uuid(),
    imageName: 'OurUser',
    link: '/contact-us/',
    title: 'Our users',
    titleButton: 'Become our user',
  },
  {
    description:
      'Keeps product articles splitted by categories with images, prices, description. Cash register related data: users database, receipt settings, and more sales transactions',
    id: uuid(),
    imageName: 'CloudStorage',
    link: '/developers/',
    title: 'Cloud storage',
    titleButton: 'See technical reference',
  },
  {
    description:
      'Manage data in the cloud directly from your browser Sales analysis tool. Possibility to order POS software online [coming soon]. Make sales directly from back-end',
    id: uuid(),
    imageName: 'BackendOffice',
    link: '/contact-us/',
    title: 'Back-end office',
    titleButton: 'GET a test access',
  },
  {
    description:
      'In some countries it’s required to use fiscal memory connected to cash registers. We provide it as a service. Supported protocols: PosPlus, CleanCash, Infrasec',
    id: uuid(),
    imageName: 'FiscalMemory',
    link: '/developers/',
    title: 'Fiscal memory in cloud',
    titleButton: 'See technical reference',
  },
  {
    description:
      'Have difficulties with certifying your POS software? Your business flow requires accepting cash payments? Use our service to 100% legally accept cash payments',
    id: uuid(),
    imageName: 'Legalization',
    link: '/contact-us/',
    title: 'Legalization',
    titleButton: 'Fill out a request',
  },
  {
    description:
      'Our cloud has a programming inteface so that any programmer, from any platform can make a real-time integration, using rich set of manuals, examples and source codes. Interfaces are backward-compatible, supports hi-load and well-documented',
    id: uuid(),
    imageName: 'OpenAPI',
    link: '/developers/',
    title: 'Open API',
    titleButton: 'See technical reference',
  },
  {
    description:
      'You may have your own server installed locally in your office. It looks like a wi-fi router and even simplier to set up',
    id: uuid(),
    imageName: 'LocalCloud',
    link: '/contact-us/',
    title: 'Your own local cloud',
    titleButton: 'See technical reference',
  },
];
