import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';
import { menu } from 'configs/constants';
import { connect } from 'react-redux';
import styles from './styles';
import { openModal } from '../../../actions';

const TopMenu = ({ classes, onClick }) => {
  const renderMenuItem = ({ url, title }) => (
    <Location key={title}>
      {({ location: { pathname } }) => {
        const isSelected = url === pathname || (pathname.includes(url, 0) && url !== '/');
        const component = title !== 'Prices' ? Link : Button;

        return (
          <MenuItem
            disableRipple
            key={title}
            component={component}
            selected={isSelected}
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
            to={url}
            onClick={title === 'Prices' && onClick}
          >
            <ListItemText
              disableTypography
              classes={{
                root: classes.menuItemText,
              }}
            >
              {title}
            </ListItemText>
          </MenuItem>
        );
      }}
    </Location>
  );

  renderMenuItem.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  };

  return (
    <MenuList className={classes.menuList}>
      {menu.map(renderMenuItem)}
    </MenuList>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(openModal()),
});

TopMenu.propTypes = {
  classes: PropTypes.shape({
    menuItem: PropTypes.string.isRequired,
    menuItemSelected: PropTypes.string.isRequired,
    menuItemText: PropTypes.string.isRequired,
    menuList: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(TopMenu));
