import commonStyles from "components/commonStyles";

const styles = theme => ({
  ...commonStyles(theme),
  button: {
    padding: "17px 46px",
    width: "100%"
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center"
  },

  form: {
    maxWidth: 410,
    marginTop: 30,
    "& p": {
      fontSize: theme.typography.pxToRem(12),
      color: "white",
      "& span": {
        textDecoration: "underline"
      }
    },
    [theme.breakpoints.down("sm")]: {
      margin: 16
    }
  },

  content: {
    position: "relative",
    "& h2": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: 16
      }
    }
  },

  container: {
    height: "calc(100vh - 147px)",
    display: "flex",
    alignItems: "center",
    maxWidth: 1280,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 60px)"
    },
    minHeight: "800px"
  },

  input: {
    "& input, label, .MuiInputBase-multiline": {
      backgroundColor: "#fff",
      borderRadius: 4
    },

    marginBottom: 16
  }
});

export default styles;
