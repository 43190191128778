import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { graphql, StaticQuery, Link } from 'gatsby';
import Button from '@material-ui/core/Button';
import Img from 'gatsby-image';
import styles from './styles';
import articles from './data';

const MainPost = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(
          filter: {
            extension: { regex: "/(jpg)|(jpeg)|(png)/" }
            relativeDirectory: { eq: "main" }
          }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid(srcSetBreakpoints: [200, 340, 520, 800, 890]) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const images = data.images.edges.reduce((summ, node) => {
        const { childImageSharp, name } = node.node;

        summ[`${name}`] = childImageSharp.fluid;

        return summ;
      }, {});

      const renderTile = ({
        description,
        id,
        imageName,
        link,
        title,
        titleButton,
      }) => (
          <div className={classes.post} key={id}>
            <div className={classes.text}>
              <h2>{title}</h2>
              <p>{description}</p>
              <Button
                to={`${link}`}
                component={Link}
                variant="contained"
                color="secondary"
                href="#form"
              >
                {titleButton}
              </Button>
            </div>
            <Img fluid={images[imageName]} className={classes.image} />
          </div>
        );

      renderTile.propTypes = {
        description: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        imageName: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        titleButton: PropTypes.string.isRequired,
      };

      return <div className={classes.root}>{articles.map(renderTile)}</div>;
    }}
  />
);

MainPost.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    post: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, { withTheme: true })(MainPost);
