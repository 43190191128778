import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { Header, Sidebar, ModalPrices } from 'components/common';
import { ModalContainer } from 'components/containers';
import { connect } from 'react-redux';
import styles from './styles';

const Layout = ({ children, openModal }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const [isSidebarOpen, setOpenSidebar] = useState(false);
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };
  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  return (
    <Location>
      {({ location }) => (
        <>
          {openModal.open && <ModalContainer><ModalPrices /></ModalContainer>}
          <Header onSidebarOpen={handleSidebarOpen} />
          <Sidebar
            onClose={handleSidebarClose}
            open={isSidebarOpen}
            variant={isDesktop ? 'persistent' : 'temporary'}
          />
          <div id="content">
            {children}
          </div>
        </>
      )}
    </Location>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  openModal: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  openModal: state,
});

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(Layout));
