import { v1 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    title: 'Which business areas are served?',
    description:
      'Risus ultrices nisl tincidunt sed et massa sed mattis. Elit sapien id ipsum placerat. Quam orci egestas in lacus, felis est ultrices euismod. Massa pretium mi cras sed. Volutpat magna nulla neque risus vel dignissim.',
  },
  {
    id: uuid(),
    title: 'How to handle internet unavailability?',
    description:
      'Risus ultrices nisl tincidunt sed et massa sed mattis. Elit sapien id ipsum placerat. Quam orci egestas in lacus, felis est ultrices euismod. Massa pretium mi cras sed. Volutpat magna nulla neque risus vel dignissim.',
  },
  {
    id: uuid(),
    title: 'What is «diagnostics tool»?',
    description:
      'Risus ultrices nisl tincidunt sed et massa sed mattis. Elit sapien id ipsum placerat. Quam orci egestas in lacus, felis est ultrices euismod. Massa pretium mi cras sed. Volutpat magna nulla neque risus vel dignissim.',
  },
  {
    id: uuid(),
    title: 'Which programming languages are supported?',
    description:
      'Risus ultrices nisl tincidunt sed et massa sed mattis. Elit sapien id ipsum placerat. Quam orci egestas in lacus, felis est ultrices euismod. Massa pretium mi cras sed. Volutpat magna nulla neque risus vel dignissim.',
  },
  {
    id: uuid(),
    title: 'How data consistancy is supported?',
    description:
      'Risus ultrices nisl tincidunt sed et massa sed mattis. Elit sapien id ipsum placerat. Quam orci egestas in lacus, felis est ultrices euismod. Massa pretium mi cras sed. Volutpat magna nulla neque risus vel dignissim.',
  },
  {
    id: uuid(),
    title: 'Does it work in real time?',
    description:
      'Risus ultrices nisl tincidunt sed et massa sed mattis. Elit sapien id ipsum placerat. Quam orci egestas in lacus, felis est ultrices euismod. Massa pretium mi cras sed. Volutpat magna nulla neque risus vel dignissim.',
  },
  {
    id: uuid(),
    title: 'Mobile, desktop or web-based POS?',
    description:
      'Risus ultrices nisl tincidunt sed et massa sed mattis. Elit sapien id ipsum placerat. Quam orci egestas in lacus, felis est ultrices euismod. Massa pretium mi cras sed. Volutpat magna nulla neque risus vel dignissim.',
  },
];
