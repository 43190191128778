import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Drawer } from "@material-ui/core";
import { menu } from "configs/constants";
import Button from "@material-ui/core/Button";
import { Link } from "gatsby";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { connect } from "react-redux";
import { openModal } from "../../../actions";

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(2, 0)
  },
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      height: "calc(100% - 64px)",
      marginTop: 64
    }
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2)
  },
  button: {
    justifyContent: "flex-start"
  }
}));

const Sidebar = ({ open, variant, onClose, onClick }) => {
  const classes = useStyles();

  const renderMenuItem = ({ url, title }) => {
    const component = title !== "Prices" ? Link : Button;

    return (
      <ListItem className={classes.item} disableGutters key={title}>
        <Button
          activeclassname={classes.active}
          className={classes.button}
          component={component}
          fullWidth
          to={url}
          onClick={title === "Prices" && onClick}
        >
          {title}
        </Button>
      </ListItem>
    );
  };

  renderMenuItem.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  };

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
      onClick={onClose}
    >
      <List className={classes.root}>{menu.map(renderMenuItem)}</List>
    </Drawer>
  );
};

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(openModal())
});

Sidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(Sidebar);
