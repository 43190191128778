import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CommonForm } from 'components/common';
import Close from 'components/svg/close';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import { closeModal } from '../../../actions';
import styles from './styles';

const ModalPrice = ({ classes, onClick }) => {
  ModalPrice.handleClickOutside = () => {
    onClick();
  };

  return (
    <div className={classes.window}>
      <h3 className={classes.textHead} style={{ marginBottom: 30 }}>Prices</h3>
      <p style={{ marginBottom: 30 }}>Please describe your demands and we get back to you shortly</p>
      <CommonForm page="price" />
      <div className={classes.buttonClose} onClick={onClick}>
        <Close />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => (
  {
    onClick: () => dispatch(closeModal()),
  });

const clickOutsideConfig = {
  handleClickOutside: () => ModalPrice.handleClickOutside,
};

ModalPrice.propTypes = {
  classes: PropTypes.shape({
    window: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired,
    buttonClose: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(onClickOutside(ModalPrice, clickOutsideConfig)));

