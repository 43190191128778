import commonStyles from 'components/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
  container: {},

  content: {
    position: 'relative',
    maxWidth: 1280,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '60px 48px',
      boxSizing: 'border-box',
    },
  },

  server: {
    '& $grid': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 75,
        marginTop: 91,
      },
    },
    '& $sectionDescription': {
      color: theme.palette.common.white,
      textAlign: 'left',
      fontWeight: 300,
      margin: 0,
      lineHeight: '130%',
    },
    '& header': {
      [theme.breakpoints.up('xs')]: {
        margin: '0 0 30px',
      },
      [theme.breakpoints.up('sm')]: {
        margin: '0 0 60px',
      },
    },
  },
  bigHeight: {
    [theme.breakpoints.up('xs')]: {
      height: 367,
    },
    [theme.breakpoints.up('sm')]: {
      height: 800,
    },
  },
  smallHeight: {
    [theme.breakpoints.up('xs')]: {
      height: 230,
    },
    [theme.breakpoints.up('sm')]: {
      height: 500,
    },
  },
});

export default styles;
