import commonStyles from 'components/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),

  container: {
    maxWidth: 410,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  input: {
    '& input': {
      borderRadius: 4,
    },
    '& p': {
      margin: '8px 0 0',
    },

  },
  inputWhite: {
    '& input, label, .MuiInputBase-multiline': {
      backgroundColor: '#fff',
    },
  },
  inputGray: {
    '& input, label, .MuiInputBase-multiline': {
      backgroundColor: 'rgba(232, 232, 232, 0.3)',
    },
  },
  row: {
    width: '100%',
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    margin: '1em 0',
    padding: '17px 46px',
    width: '100%',
  },
  terms: {
    fontSize: '12px !important',
    marginBottom: '0 !important',
    '& span': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
});

export default styles;
