import React from 'react';
import { Link } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { siteName } from 'configs/constants';
import TopMenu from 'components/common/TopMenu';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import styles from './styles';

const Header = ({ classes, onSidebarOpen }) => {
  const renderLetter = (letter, index) => (
    <span key={`${letter}_${index}`} className={classes.letter}>
      {letter}
    </span>
  );

  const siteNameLetters = [...siteName.trim()];

  return (
    <AppBar elevation={0} position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbarRoot}>
        <Link to="/" className={classes.title}>
          {siteNameLetters.map(renderLetter)}
        </Link>
        {/* <div className={classes.spacer} /> */}
        <Hidden smDown>
          <TopMenu />
        </Hidden>
        <Hidden mdUp>
          <IconButton onClick={onSidebarOpen} style={{ padding: 0 }}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.shape({
    appBar: PropTypes.string.isRequired,
    letter: PropTypes.string.isRequired,
    spacer: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    toolbarRoot: PropTypes.string.isRequired,
  }).isRequired,
  onSidebarOpen: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(Header);
