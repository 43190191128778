import { withStyles } from "@material-ui/core/styles";
import React from "react";
import axios from "axios";
import get from "lodash/get";
import qs from "qs";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import { googleAppUrl } from "configs/constants";
import { ContactUsFormSchema } from "schemes";
import { graphql, StaticQuery } from "gatsby";
import styles from "./styles";
import BgImage from "../BgImage";
import Title from "../Title";

const initialValues = {
  description: "",
  email: "",
  phone: ""
};
const googleFormProps = {
  formDataNameOrder: JSON.stringify(["email", "phone", "description"]),
  formGoogleSheetName: "Contact Us Form",
  formName: "Contact Us Form"
};

const ContactUsForm = ({ classes }) => {
  const textFieldProps = {
    autoComplete: "off"
  };

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = React.useCallback(
    async (values, { setSubmitting, resetForm, setStatus }) => {
      try {
        setSubmitting(true);

        const options = {
          data: qs.stringify({
            ...values,
            ...googleFormProps
          }),
          headers: { "content-type": "application/x-www-form-urlencoded" },
          method: "post",
          url: googleAppUrl
        };
        const response = await axios(options);
        const { data } = response;

        if (data.result === "success") {
          enqueueSnackbar("Your message has been sent. Thank you!", {
            variant: "success"
          });
        } else if (data.result === "error") {
          const errorMessage = get(data, "error.message", "Unexpected error");

          enqueueSnackbar(errorMessage, { variant: "error" });
        }
        setStatus({ success: true });
        resetForm();
      } catch (err) {
        enqueueSnackbar(err, {
          variant: "error"
        });
        setStatus({ success: false });
      } finally {
        setSubmitting(false);
      }
    }
  );

  return (
    <StaticQuery
      query={graphql`
        query {
          image: allFile(
            filter: { relativePath: { eq: "headers/ContactUs.png" } }
          ) {
            edges {
              node {
                id
                childImageSharp {
                  id
                  fluid(
                    srcSetBreakpoints: [200, 340, 520, 800, 890]
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const bgImage = get(
          data,
          "image.edges[0].node.childImageSharp.fluid",
          {}
        );

        return (
          <BgImage fluid={bgImage}>
            <section id="any-questions" className={classes.container}>
              <div className={classes.content}>
                <Title title="Contact" bold=" us" />
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={ContactUsFormSchema}
                  render={props => {
                    const {
                      handleSubmit,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      values,
                      isSubmitting
                    } = props;

                    const hasDescriptionError =
                      touched.description && Boolean(errors.description);
                    const hasEmailError =
                      touched.email && Boolean(errors.email);
                    const hasPhoneError =
                      touched.phone && Boolean(errors.phone);
                    const hasFormError =
                      hasDescriptionError || hasEmailError || hasPhoneError;

                    return (
                      <Form
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        className={classes.form}
                      >
                        <TextField
                          variant="outlined"
                          fullWidth
                          required
                          InputProps={textFieldProps}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Email *"
                          error={hasEmailError}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          helperText={errors.email}
                          className={classes.input}
                        />

                        <TextField
                          variant="outlined"
                          fullWidth
                          required
                          InputProps={textFieldProps}
                          id="phone"
                          name="phone"
                          type="phone"
                          placeholder="Phone with country code *"
                          error={hasPhoneError}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          helperText={errors.phone}
                          className={classes.input}
                        />

                        <TextField
                          variant="outlined"
                          multiline
                          rows="10"
                          fullWidth
                          required
                          InputProps={textFieldProps}
                          id="description"
                          name="description"
                          placeholder="Message *"
                          error={hasDescriptionError}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          helperText={errors.description}
                          className={classes.input}
                        />

                        <div className={classes.buttonContainer}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="large"
                            className={classes.button}
                            disabled={isSubmitting || hasFormError}
                          >
                            Send
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                />
              </div>
            </section>
          </BgImage>
        );
      }}
    />
  );
};

ContactUsForm.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    buttonContainer: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired
  }).isRequired,
  errors: PropTypes.shape({
    description: PropTypes.bool.isRequired,
    email: PropTypes.bool.isRequired,
    phone: PropTypes.bool.isRequired
  }),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  touched: PropTypes.shape({
    description: PropTypes.bool.isRequired,
    email: PropTypes.bool.isRequired,
    phone: PropTypes.bool.isRequired
  }),
  values: PropTypes.shape({
    description: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired
  })
};

ContactUsForm.defaultProps = {
  errors: {
    description: false,
    email: false,
    phone: false
  },
  handleBlur: () => {},
  handleChange: () => {},
  handleSubmit: () => {},
  isSubmitting: false,
  touched: {
    description: false,
    email: false,
    phone: false
  },
  values: initialValues
};

export default withStyles(styles, { withTheme: true })(ContactUsForm);
