import commonStyles from 'components/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
  window: {
    maxWidth: 630,
    borderRadius: 6,
    backgroundColor: '#fff',
    textAlign: 'center',
    boxSizing: 'border-box',
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      padding: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '60px 110px',
    },
  },
  buttonClose: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
  },
});

export default styles;
