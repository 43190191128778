import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import articles from './data';

const KnowMore = ({classes}) => (
  <section className={classes.knowMore}>
    <h2>Want to know more?</h2>
    <h4>Check out our additional features</h4>
    {articles.map((ar) => (
      <details key={ar.id}>
        <summary>
          {ar.title}
          <svg
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.icon}
          >
            <path
              d="M1.22182 8.27612L8.99999 0.497607L16.7778 8.27612C16.9751 8.47338 17.0737 8.73191 17.0737 8.99011C17.0737 9.24831 16.9751 9.50684 16.7782 9.7041C16.384 10.0986 15.7444 10.0986 15.3502 9.7041L8.99999 3.35358L2.6498 9.7041C2.25561 10.0986 1.61601 10.0986 1.22182 9.7041C0.82729 9.30957 0.827289 8.67065 1.22182 8.27612Z"
              fill="#141414"
            />
          </svg>
        </summary>
        <p>{ar.description}</p>
      </details>
    ))}
  </section>
);

KnowMore.propTypes = {
  classes: PropTypes.shape({
    knowMore: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(KnowMore);
