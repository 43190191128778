import React from 'react';
import {Provider} from 'react-redux';
import {ThemeTopLayout} from 'components/common';
import PropTypes from 'prop-types';
import {SnackbarProvider} from 'notistack';
import createStore from '../../state/createStore';

export default function TopLayout({children, theme}) {
  const store = createStore();

  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>
      </SnackbarProvider>
    </Provider>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({}).isRequired,
};
