import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import axios from 'axios';
import get from 'lodash/get';
import qs from 'qs';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {Formik, Form} from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {useSnackbar} from 'notistack';
import {googleAppUrl} from 'configs/constants';
import {PricesFormSchema} from 'schemes';
import styles from './styles';

const initialValues = {
  description: '',
  email: '',
  phone: '',
};
const googleFormProps = {
  formDataNameOrder: JSON.stringify(['email', 'phone', 'description']),
  formGoogleSheetName: 'Question FormQuestion Form on Title',
  formName: 'Price Form',
};

const PricesForm = ({
  classes,
}) => {
  const textFieldProps = {
    autoComplete: 'off',
  };

  const {enqueueSnackbar} = useSnackbar();

  const onSubmit = React.useCallback(async (values, {setSubmitting, resetForm, setStatus}) => {
    try {
      setSubmitting(true);

      const options = {
        data: qs.stringify({
          ...values,
          ...googleFormProps,
        }),
        headers: {'content-type': 'application/x-www-form-urlencoded'},
        method: 'post',
        url: googleAppUrl,
      };
      const response = await axios(options);
      const {data} = response;

      if (data.result === 'success') {
        enqueueSnackbar('Your message has been sent. Thank you!', {
          variant: 'success',
        });
      } else if (data.result === 'error') {
        const errorMessage = get(data, 'error.message', 'Unexpected error');

        enqueueSnackbar(errorMessage, {variant: 'error'});
      }
      setStatus({success: true});
      resetForm();
    } catch (err) {
      enqueueSnackbar(err, {
        variant: 'error',
      });
      setStatus({success: false});
    } finally {
      setSubmitting(false);
    }
  });

  return (
    <section id="any-questions">
      <div className={classes.content}>
        <Grid className={classes.container} container>
          <p className={classes.sectionDescription}>
            Please describe your demands and we get back to you shortly
          </p>
        </Grid>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={PricesFormSchema}
          render={(props) => {
            const {
              handleSubmit,
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              isSubmitting,
            } = props;

            const hasDescriptionError = touched.description && Boolean(errors.description);
            const hasEmailError = touched.email && Boolean(errors.email);
            const hasPhoneError = touched.phone && Boolean(errors.phone);
            const hasFormError = hasDescriptionError || hasEmailError || hasPhoneError;

            return (
              <Form
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows="10"
                  fullWidth
                  required
                  InputProps={textFieldProps}
                  id="description"
                  name="description"
                  label="Description"
                  error={hasDescriptionError}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  helperText={errors.description}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  InputProps={textFieldProps}
                  id="email"
                  name="email"
                  type="email"
                  label="Email"
                  error={hasEmailError}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  helperText={errors.email}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  InputProps={textFieldProps}
                  id="phone"
                  name="phone"
                  type="phone"
                  label="Phone number with country code"
                  error={hasPhoneError}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  helperText={errors.phone}
                />

                <div className={classes.buttonContainer}>
                  <Button
                    margin="normal"
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    disabled={isSubmitting || hasFormError}
                  >
                    Send
                  </Button>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </section>
  );
};

PricesForm.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    buttonContainer: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    sectionDescription: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    description: PropTypes.bool.isRequired,
    email: PropTypes.bool.isRequired,
    phone: PropTypes.bool.isRequired,
  }),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  touched: PropTypes.shape({
    description: PropTypes.bool.isRequired,
    email: PropTypes.bool.isRequired,
    phone: PropTypes.bool.isRequired,
  }),
  values: PropTypes.shape({
    description: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }),
};

PricesForm.defaultProps = {
  errors: {
    description: false,
    email: false,
    phone: false,
  },
  handleBlur: () => {},
  handleChange: () => {},
  handleSubmit: () => {},
  isSubmitting: false,
  touched: {
    description: false,
    email: false,
    phone: false,
  },
  values: initialValues,
};

export default withStyles(styles, {withTheme: true})(PricesForm);
