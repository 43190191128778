import commonStyles from 'components/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
  button: {
    margin: '1em 0',
    padding: '17px 46px',
    width: '100%',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  flex: {
    display: 'flex',
  },

  container: {
    maxWidth: 410,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  row: {
    width: '100%',
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0,
    },
  },

  terms: {
    fontSize: '12px !important',
    marginBottom: '0 !important',
    '& span': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },

  copyright: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(16),
    marginBottom: '0 !important',
    marginTop: '93px !important',
  },

  input: {
    backgroundColor: 'white',
    borderRadius: 4,
  },
  questionsForm: {
    paddingTop: 142,
    paddingBottom: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '60px 16px 0 16px',
    },
    '& h2': {
      color: 'white',
      textTransform: 'none',
      fontSize: theme.typography.pxToRem(36),
      fontWeight: 500,
      marginBottom: 30,
      textAlign: 'center',
    },
    '& p': {
      color: 'white',
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 300,
      margin: '0 0 30px',
      textAlign: 'center',
    },
  },
});

export default styles;
