import commonStyles from 'components/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
  knowMore: {
    margin: '0 auto 120px',
    width: 850,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      margin: '60px 16px',
    },
    '& h2': {
      fontSize: theme.typography.pxToRem(48),
      color: theme.palette.black.main,
      fontWeight: 500,
      margin: '0 0 30px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        marginBottom: 16,
      },
    },
    '& h4': {
      fontSize: theme.typography.pxToRem(18),
      color: theme.palette.grey.dark,
      fontWeight: 300,
      margin: '0 0 60px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 16,
      },
    },
    '& summary': {
      color: theme.palette.black.main,
      fontSize: theme.typography.pxToRem(24),
      padding: '25px 0',
      borderTop: '1px solid #E8E8E8',
      fontWeight: 500,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&:focus': {
        outline: 'none',
      },
      '&::-webkit-details-marker': {
        display: 'none',
      },
      '&::after': {},
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(20),
        padding: '16px 0',
      },
    },
    '& details': {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 300,
      color: theme.palette.grey.dark,
      '& p': {
        margin: '0 0 30px',
      },
      '&:last-child summary': {
        borderBottom: '1px solid #E8E8E8',
      },
      '&:last-child[open] summary': {
        borderBottom: 0,
      },
      '&[open] svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  icon: {
    marginRight: 7,
    height: 10,
    transition: '0.5s',
  },
});

export default styles;
