import { createTheme, colors } from "@material-ui/core";

// export const breakpoints = {
//   lg: 1220,
//   md: 996,
//   sm: 768,
//   xl: 1920,
//   xs: 480,
//   xxs: 0,
// };
// A custom theme for this app
const theme = createTheme({
  // breakpoints: {
  //   keys: Object.keys(breakpoints),
  //   values: breakpoints,
  // },
  overrides: {
    MuiButton: {
      containedSecondary: {
        color: "white",
        fontSize: 16,
        padding: "19px 38px",
        "&:hover": {
          backgroundColor: "#F17743"
        }
      }
    }
  },
  footerHeight: {
    lg: 121
  },
  headerHeight: {
    xs: 60
  },
  palette: {
    background: {
      default: "#ffffff",
      footer: "#e6e6e6",
      paper: "#fafafa"
    },
    error: {
      main: colors.red.A400
    },
    primary: {
      main: "#F89336"
    },
    secondary: {
      main: "#F7973F"
    },
    white: {
      main: "#FFFFFF"
    },
    black: {
      main: "#141414"
    },
    grey: {
      dark: "#434343",
      darker: "#696969",
      main: "#CDCDCD",
      light: "#E8E8E8"
    },
    violet: {
      main: "#8B36F8"
    },
    purple: {
      main: "#BA36F8"
    },
    yellow: {
      main: "#F8B636"
    },
    red: {
      main: "#E52B4D"
    },

    type: "light",
    vividOrange: "#e66c00"
  },
  spacing: 10,
  typography: {
    body2: {
      color: "#ffffff"
    },
    fontFamily: ["Roboto", "Open Sans", "Arial", "sans-serif"].join(","),
    fontSize: 14,
    h6: {
      fontSize: 36,
      fontWeight: "600",
      lineHeight: 1.11,
      whiteSpace: "normal"
    },
    sitename: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: 33
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1.5
    },
    useNextVariants: true
  }
});

export default theme;
