import { v1 as uuid} from "uuid";

export default [
  {
    description:
      "READ IT FIRST. What’s the main idea? Are the companies isolated? How can I address my company?",
    id: uuid(),
    imageName: "data-domain",
    link: "/developers/data-domain/",
    title: "Data domain"
  },
  {
    description: "What API suits you? Choose your business case!",
    id: uuid(),
    imageName: "api-chooser",
    link: "/developers/api-chooser/",
    title: "API Chooser"
  },
  {
    description:
      "How to handle errors? What is the best practice? What sort of message user should see?",
    id: uuid(),
    imageName: "error-handling",
    link: "/developers/error-handling/",
    title: "Error Handling"
  },
  {
    description: "What API suits you? Choose your business case!",
    id: uuid(),
    imageName: "json",
    link: "/developers/json-rest-api-guidelines/",
    title: "JSON-REST API Guidelines"
  },
  {
    description: "How to get access to the secured data?",
    id: uuid(),
    imageName: "api-keys",
    link: "/developers/api-key/",
    title: "API Keys"
  },
  {
    description:
      "Server has native java classes delivered as JAR file, javadoc, examples on java language.",
    id: uuid(),
    imageName: "java",
    link: "/developers/java/",
    title: "Java"
  },
  {
    description:
      "Integration with Microsoft .NET technology, C#, Visual Basic and other .NET languages.",
    id: uuid(),
    imageName: "dot-net",
    link: "/developers/dot-net/",
    title: ".NET"
  },
  {
    description: "What’s the idea of synchronization? How caching works?",
    id: uuid(),
    imageName: "sync",
    link: "/developers/sync/",
    title: "Synchronization and Caching"
  },
  {
    description:
      "How to print a receipt? How to report historical information about sale? What’s calculation rules?",
    id: uuid(),
    imageName: "make-sale",
    link: "/developers/make-sale/",
    title: "Make Sale"
  },
  {
    description:
      "Short description of features and bugfixes delivered per specific server version.",
    id: uuid(),
    imageName: "release-notes",
    link: "/developers/release-notes/",
    title: "Release Notes"
  },
  {
    description:
      "List of all available url’s for data exchange with their specification and code samples.",
    id: uuid(),
    imageName: "json-rest-reference",
    link: "https://site.srv4pos.com/rest.html",
    title: "JSON-REST Reference"
  }
];
