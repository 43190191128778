const commonStyles = (theme) => ({
  button: {
    '&:focus, &:hover': {
      textDecoration: 'none',
    },
    color: '#fff',
    fontSize: theme.typography.pxToRem(16),
    margin: '70px 0 45px',
    padding: '15px 50px',
  },

  container: {
    '& h2': {
      '& span': {
        color: theme.palette.primary.main,
      },
      color: theme.palette.common.black,
      fontSize: theme.typography.pxToRem(40),
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontWeight: 300,
      letterSpacing: 5.7,
      lineHeight: 1.15,
      margin: '0 0 60px',
      position: 'relative',
      textTransform: 'uppercase',
    },
    textAlign: 'center',
  },

  content: {
    marginTop: 60,
    marginBottom: 120,
    '& svg': {
      margin: '0 auto',
    },
    '& $pageTitle': {
      color: '#000',
      fontFamily: 'IntroCondFree',
      fontSize: theme.typography.pxToRem(39),
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontWeight: 900,
      letterSpacing: 'normal',
      margin: `${theme.spacing(4.3)}px ${theme.spacing(
        4.2,
      )}px ${theme.spacing()}px`,
      textTransform: 'upperCase',
    },

    color: '#000',
    flexGrow: 1,
    padding: 0,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      margin: 16,
    },

    [theme.breakpoints.up('lg')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 850,
    },
  },
  flex: {
    display: 'flex',
  },
  helper: {
    minHeight: '1em',
  },
  pageTitle: {},
  sectionDescription: {},
  spacer: {
    flex: '1 1 auto',
  },

  textHead: {
    fontWeight: 'normal',
    color: '#141414',
    margin: 0,
  },
  textBold: {
    color: '#0E0918',
    fontWeight: '600',
  },
  textColor: {
    color: '#F89336',
    fontWeight: '500',
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  textDescription: {
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  h4Absolute: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: '500',
    position: 'absolute',
  },

  head: {
    position: 'relative',
    height: 100,
    marginBottom: 20,
    '&::before': {
      counterIncrement: 'inc',
      content: 'counter(inc)',
      position: 'absolute',
      top: 0,
      left: 0,
      color: '#E8E8E8',
      opacity: '0.5',
      fontSize: theme.typography.pxToRem(100),
      transform: 'translate(-50%, -18%)',
      [theme.breakpoints.down('sm')]: {
        left: 30,
      },
    },
  },
  picture: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
  },
  case: {
    borderBottom: '1px solid #E8E8E8',
    paddingTop: 20,
    paddingBottom: 60,
    '&:last-child': {
      border: 'none',
    },
  },
  flex30: {
    display: 'flex',
    wordBreak: 'break-word',
  },
  bigString: {
    width: '40%',
  },
  smallString: {
    width: '30%',
  },
  flex50: {
    display: 'flex',
    marginBottom: 20,
    '& h5': {
      width: '50%',
    },
    '& p': {
      width: '50%',
    },
  },
  table: {
    '& $flex30': {
      marginBottom: 20,
    },
  },
  tableMini: {
    maxWidth: 400,
    '& div': {
      display: 'flex',
      '& p': {
        width: '33%',
      },
    },
  },
  tableColor: {
    '& $flex30': {
      padding: 10,
      '&:nth-child(2n-1)': {
        backgroundColor: 'rgba(205, 205, 205, 0.15)',
      },
      '&:nth-child(2n)': {
        backgroundColor: 'rgba(205, 205, 205, 0.03)',
      },
    },
  },
});

export default commonStyles;
