import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { globalHistory } from '@reach/router';
import classnames from 'classnames';
import BgImage from '../BgImage';
import styles from './styles';
import Title from '../Title';
import HeaderData from './data';

const PageHeader = ({ classes }) => {
  const CurrentData = HeaderData.filter(
    (el) => el.page === globalHistory.location.pathname,
  )[0];

  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: {
              extension: { regex: "/(jpg)|(jpeg)|(png)/" }
              relativeDirectory: { eq: "title" }
            }
          ) {
            edges {
              node {
                name
                childImageSharp {
                  fluid(
                    srcSetBreakpoints: [200, 340, 520, 800, 890]
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const images = data.images.edges.reduce((summ, node) => {
          const { childImageSharp, name } = node.node;

          summ[`${name}`] = childImageSharp.fluid;

          return summ;
        }, {});

        const bigImages = classnames(classes.content, classes.bigHeight);
        const smallImages = classnames(classes.content, classes.smallHeight);

        return (
          <BgImage fluid={images[CurrentData.image]} className={classes.server}>
            <div
              className={CurrentData.height === '800' ? bigImages : smallImages}
            >
              <Grid
                className={classes.container}
                container
                justify={CurrentData.page === '/' ? 'flex-end' : 'flex-start'}
              >
                <Grid
                  item
                  md={CurrentData.page === '/' ? 6 : 12}
                >
                  <header>
                    <Title
                      title={CurrentData.title}
                      bold={CurrentData.bold}
                      doubleline={CurrentData.height === '800'}
                    />
                  </header>
                  <p className={classes.sectionDescription}>
                    {CurrentData.description}
                  </p>
                </Grid>
              </Grid>
            </div>
          </BgImage>
        );
      }}
    />
  );
};

PageHeader.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    sectionDescription: PropTypes.string.isRequired,
    server: PropTypes.string.isRequired,
    bigHeight: PropTypes.string.isRequired,
    smallHeight: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(PageHeader);

