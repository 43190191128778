import libPhoneNumber from 'google-libphonenumber';
import {
  addMethod as yupAddMethod, object as yupObject, string as yupString,
} from 'yup';

const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

yupAddMethod(yupString, 'phone', function phoneValidation() {
  return this.test({
    exclusive: true,
    message: 'Must be a phone number',
    name: 'phone',
    test: (value) => {
      try {
        if (!value) {
          return true;
        }

        let phoneNumber = `${value}`;

        if (phoneNumber.startsWith('00')) {
          phoneNumber = phoneNumber.slice(2);
        }

        if (!phoneNumber.startsWith('+')) {
          phoneNumber = `+${phoneNumber}`;
        }

        const phone = phoneUtil.parse(phoneNumber);

        return !value || phoneUtil.isPossibleNumber(phone, '');
      } catch (e) {
        return false;
      }
    },
  });
});

const QuestionSchema = yupObject().shape({
  description: yupString().trim().required('Description is required'),
  email: yupString()
    .email('Email is invalid')
    .trim()
    .required('Email is required'),
  phone: yupString()
    .phone('Invalid phone number')
    .trim()
    .required('Phone is required'),
});

export default QuestionSchema;
