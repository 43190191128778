import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';

const Title = ({
  classes, title, bold, doubleline,
}) => (
  <h2 className={classes.head}>
      {title}
      {doubleline && <br />}
      <span>{` ${bold}`}</span>
    </h2>
);

Title.propTypes = {
  classes: PropTypes.shape({
    head: PropTypes.string.isRequired,
  }).isRequired,
  bold: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  doubleline: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Title);
