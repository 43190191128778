export default [
  {
    page: '/',
    title: 'Server for',
    bold: 'Point of sale',
    image: 'main-server',
    description:
      'Server for Point Of Sale is a Web Service, available 24/7/365, which keeps a data of POS terminals (cash registers) in a central place and collects cash transactions',
    height: '800',
  },
  {
    page: '/developers/',
    title: 'Developer',
    bold: 'Center',
    image: 'developers',
    description: '',
    height: '800',
  },
  {
    page: '/developers/data-domain/',
    title: 'Data',
    bold: 'Domain',
    image: 'data-domain',
    description: '',
    height: '500',
  },
  {
    page: '/developers/api-chooser/',
    title: 'API',
    bold: 'Chooser',
    image: 'api-chooser',
    description: '',
    height: '500',
  },
  {
    page: '/developers/error-handling/',
    title: 'Error',
    bold: 'Handling',
    image: 'error-handling',
    description: '',
    height: '500',
  },
  {
    page: '/developers/json-rest-api-guidelines/',
    title: 'JSON-REST',
    bold: 'API Guidlines',
    image: 'json',
    description: '',
    height: '500',
  },
  {
    page: '/developers/api-key/',
    title: 'API',
    bold: 'Keys',
    image: 'api-keys',
    description: '',
    height: '500',
  },
  {
    page: '/developers/java/',
    title: '',
    bold: 'JAVA',
    image: 'java',
    description: '',
    height: '500',
  },
  {
    page: '/developers/dot-net/',
    title: '',
    bold: '.NET',
    image: 'dot-net',
    description: '',
    height: '500',
  },
  {
    page: '/developers/sync/',
    title: 'Synchronization',
    bold: 'and Caching',
    image: 'sync',
    description: '',
    height: '500',
  },
  {
    page: '/developers/make-sale/',
    title: 'Make',
    bold: 'Sale',
    image: 'make-sale',
    description: '',
    height: '500',
  },
  {
    page: '/developers/release-notes/',
    title: 'Release',
    bold: 'Notes',
    image: 'release-notes',
    description: '',
    height: '500',
  },
];
