import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

const ModalContainer = ({ classes, children }) => (
  <div className={classes.container}>{children}</div>
);

ModalContainer.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired
  }).isRequired,
  children: PropTypes.element.isRequired
};

export default withStyles(styles, { withTheme: true })(ModalContainer);
