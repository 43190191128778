import commonStyles from 'components/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
  head: {
    '& span': {
      fontWeight: 600,
    },
    color: '#fff',
    letterSpacing: 0,

    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: 300,
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: '28px',
      margin: '0 0 16px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(60),
      lineHeight: 'inherit',
      margin: 0,
    },
  },
});

export default styles;
