import commonStyles from "components/commonStyles";

const styles = theme => {
  const topPadding = `${theme.spacing(3.8)}px`;
  const sidePadding = `${theme.spacing(4)}px`;
  const bottomPadding = `${theme.spacing(2.5)}px`;

  return {
    ...commonStyles(theme),
    "@keyframes color_change": {
      from: { color: theme.palette.common.black },
      to: { color: theme.palette.primary.main }
    },

    appBar: {
      backgroundColor: theme.palette.common.white
    },

    flex: {
      flexGrow: 1
    },

    letter: {
      "&:nth-last-child(-n+4)": {
        color: theme.palette.primary.main
      },

      [theme.breakpoints.up("xs")]: {
        fontSize: theme.typography.pxToRem(28)
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(32)
      }
    },

    title: {
      "&:hover, &:focus": {
        textDecoration: "none"
      },
      color: theme.palette.common.black,
      fontFamily: ["Roboto Condensed", "sans-serif"].join(","),
      fontSize: 0,
      fontStretch: "condensed",
      fontStyle: "normal",
      fontWeight: "bold",
      letterSpacing: "6.6px",
      lineHeight: 1,
      textDecoration: "none",
      textTransform: "uppercase"
    },
    toolbarRoot: {
      justifyContent: "space-between",
      [theme.breakpoints.up("xs")]: {
        paddingLeft: 24,
        paddingRight: 26
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: 42,
        paddingRight: 42
      },
      [theme.breakpoints.up("xs")]: {
        height: theme.headerHeight.xs
      },
      [theme.breakpoints.up("md")]: {
        padding: `${topPadding} ${sidePadding} ${bottomPadding}`
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: theme.breakpoints.values.lg,
        width: "100%",
        paddingTop: 41,
        paddingBottom: 41
      }
    }
  };
};

export default styles;
