import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import { graphql, StaticQuery } from 'gatsby';
import { CommonForm } from 'components/common';
import styles from './styles';
import BgImage from '../BgImage';

const QuestionsForm = ({ classes }) => (
  <StaticQuery
    query={graphql`
        query {
          image: allFile(filter: { relativePath: { eq: "title/footer.jpg" } }) {
            edges {
              node {
                id
                childImageSharp {
                  id
                  fluid(
                    srcSetBreakpoints: [200, 340, 520, 800, 890]
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
    render={(data) => {
      const bgImage = get(
        data,
        'image.edges[0].node.childImageSharp.fluid',
        {},
      );

      return (
        <BgImage fluid={bgImage}>
          <section className={classes.questionsForm} id="any-questions">
            <div className={classes.content} style={{ margin: '0 auto' }}>
              <Grid className={classes.container} container justify="center">
                <Grid item xs={8}>
                  <header>
                    <h2>Any questions?</h2>
                  </header>

                  <p className={classes.sectionDescription}>
                    Please get in touch with us
                  </p>
                </Grid>
              </Grid>
              <CommonForm page="main" />
              <p className={classes.copyright}>
                © 2015-2019 Server For Pos. All rights reserved
              </p>
            </div>
          </section>
        </BgImage>
      );
    }}
  />
);

QuestionsForm.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    buttonContainer: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    questionsForm: PropTypes.string.isRequired,
    sectionDescription: PropTypes.string.isRequired,
    copyright: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    row: PropTypes.string.isRequired,
    terms: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, { withTheme: true })(QuestionsForm);
