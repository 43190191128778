import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {
  siteName, siteDescription, siteImage, siteUrl,
} from 'configs/constants';

const getSchemaOrgJSONLD = ({url, pageTitle}) => {
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      alternateName: siteName,
      name: pageTitle,
      url: `${siteUrl}#website`,
    },
    {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      name: pageTitle,
      url: `${siteUrl}${url}#webpage`,
    },
    /*
    {
      "@context": "http://schema.org",
      "@id": "${SITE_URL}/#organization",
      "@type": "Organization",
      "url": "${SITE_URL}",
      "name": "${SITE_NAME}"
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "telephone": "${CONTACT_PHONE}",
          "contactType": "sales",
          "areaServed": "US"
        },
        {
          "@type": "ContactPoint",
          "telephone": "${CONTACT_PHONE}",
          "contactType": "technical support",
          "areaServed": "US",
          "availableLanguage": ["EN", "ES"]
        },
        {
          "@type": "ContactPoint",
          "telephone": "${CONTACT_PHONE}",
          "contactType": "customer support",
          "areaServed": "US",
          "availableLanguage": ["EN", "ES"]
        }
      ],
      "sameAs": [
        "https://www.linkedin.com/company/srv4pos",
        "https://twitter.com/srv4pos",
      ]
    },
    */
  ];

  return schemaOrgJSONLD;
};

const SEO = ({
  title, description, url, image,
}) => {
  const pageTitle = title
    ? `${title} - ${siteName}`
    : siteName;

  const schemaOrgJSONLD = getSchemaOrgJSONLD({pageTitle, url});
  const seoDescription = description || siteDescription;
  const seoImage = image || siteImage;
  const seoUrl = `${siteUrl}${url}`;

  return (
    <Helmet>
      <meta name="description" content={seoDescription} />
      {seoImage && (
        <meta name="image" content={seoImage} />
      )}
      <title>{pageTitle}</title>
      <meta name="theme-color" content="#EEEEEE" />
      <html lang="en" amp />

      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={seoUrl} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:image" content={seoImage} />
    </Helmet>
  );
};

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

SEO.defaultProps = {
  description: null,
  image: null,
  title: 'Server 4 POS',
  url: null,
};

export default SEO;
