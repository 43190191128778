import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import Img from 'gatsby-image';
import styles from './styles';

const BgImage = ({
  classes, fluid, title, children, className,
}) => {
  const containerClasses = classnames(className, classes.root);

  return (
    <div className={containerClasses}>
      <Img
        className={classes.bgImage}
        fluid={fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        alt={title}
      />
      {children}
    </div>
  );
};

BgImage.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.shape({
    bgImage: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
  }).isRequired,
  fluid: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
};

BgImage.defaultProps = {
  className: null,
  title: '',
};

export default withStyles(styles, {withTheme: true})(BgImage);
