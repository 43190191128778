import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { graphql, StaticQuery, Link } from "gatsby";
import Img from "gatsby-image";
import styles from "./styles";
import articles from "./data";

const DeveloperPost = ({ classes }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: {
              extension: { regex: "/(jpg)|(jpeg)|(png)/" }
              relativeDirectory: { eq: "developers" }
            }
          ) {
            edges {
              node {
                name
                childImageSharp {
                  fluid(srcSetBreakpoints: [200, 340, 520, 800, 890]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const images = data.images.edges.reduce((summ, node) => {
          const { childImageSharp, name } = node.node;

          summ[`${name}`] = childImageSharp.fluid;

          return summ;
        }, {});

        const renderTile = ({ description, id, imageName, title, link }) => {
          return (
            <div className={classes.post} key={id}>
              {title === "JSON-REST Reference" ? (
                <a href={link}>
                  {renderPost(description, id, imageName, title)}
                </a>
              ) : (
                <Link to={`${link}`}>
                  {renderPost(description, id, imageName, title)}
                </Link>
              )}
            </div>
          );
        };

        const renderPost = (description, id, imageName, title) => {
          return (
            <>
              <Img fluid={images[imageName]} className={classes.image} />
              <div className={classes.text}>
                <div className={classes.title}>
                  <h2>{title}</h2>
                  <svg
                    width="30"
                    height="22"
                    viewBox="0 0 30 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.5915 10.0138L20.6639 1.08625C20.1193 0.541562 19.2359 0.541562 18.6912 1.08625C18.1464 1.63105 18.1464 2.51421 18.6912 3.05901L25.2374 9.60522H1.39492C0.624591 9.60522 0 10.2298 0 11.0001C0 11.7704 0.624591 12.3951 1.39492 12.3951H25.2374L18.6914 18.9413C18.1466 19.4861 18.1466 20.3692 18.6914 20.914C18.9637 21.1862 19.3208 21.3226 19.6778 21.3226C20.0348 21.3226 20.3918 21.1862 20.6642 20.914L29.5915 11.9865C30.1363 11.4417 30.1363 10.5586 29.5915 10.0138Z"
                      fill="#141414"
                    />
                  </svg>
                </div>
                <p>{description}</p>
              </div>
            </>
          );
        };

        renderTile.propTypes = {
          description: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          imageName: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired
        };

        return <div className={classes.root}>{articles.map(renderTile)}</div>;
      }}
    />
  );
};

DeveloperPost.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    post: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(styles, { withTheme: true })(DeveloperPost);
