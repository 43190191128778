/*
 * This file is part of the www.srv4pos.com package.
 * Ⓒ 2017 — 2019 www.srv4pos.com
 * For the full copyright and license information,
 * please view the LICENSE file that was distributed with this source code.
 */

const styles = (theme) => ({
  menuItem: {
    '&$menuItemSelected, &:hover, &:focus': {
      '& $menuItemText': {
        color: theme.palette.primary.main,
      },
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,

      textDecoration: 'none',
    },
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(16),
    fontStretch: 'condensed',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: '1.6px',
    padding: `${theme.spacing(1.2)}px`,
    textDecoration: 'none',

    textTransform: 'uppercase',
  },

  menuItemSelected: {
    color: theme.palette.primary.main,
  },
  menuItemText: {
    paddingRight: 0,
  },
  menuList: {
    display: 'flex',
    listStyle: 'none',
    padding: `0 ${theme.spacing(2)}px`,
  },
});

export default styles;
