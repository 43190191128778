import commonStyles from "components/commonStyles";

const styles = theme => ({
  ...commonStyles(theme),
  container: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(20, 20, 20, 0.5)",
    zIndex: "2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "750px",

    "&+header+div+#content, +header+#content": {
      overflow: "hidden",
      maxHeight: "calc(100vh - 147px)",
      minHeight: "603px",
      [theme.breakpoints.down("sm")]: {
        minHeight: "690px",
        maxHeight: "calc(100vh - 60px)"
      }
    }
  }
});

export default styles;
