/* eslint-disable max-len,prettier/prettier */

export const siteUrl = process.env.SITE_URL || 'https://srv4pos.com';
export const siteName = 'server4pos';
export const siteDescription = 'Server for Point Of Sale is a Web Service, available 24/7/365, which keeps a data of POS terminals in central place and collects Cash Transactions';
export const siteImage = `${siteUrl}/img/logo.png`;
export const menu = [
  { title: 'Overview', url: '/' },
  { title: 'Prices' },
  { title: 'Developer Center', url: '/developers/' },
  { title: 'Contact us', url: '/contact-us/' },
];
export const googleAppUrl = 'https://script.google.com/macros/s/AKfycbx9NgQPudcAPTzs7U9A1KBzu2HFO6OtlvrX-9vqsS-7S7mUUNc/exec';

