const styles = (theme) => ({
  copyright: {
    alignSelf: 'center',
  },
  root: {
    backgroundColor: theme.palette.background.footer,
    color: theme.palette.action.active,
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.typography.pxToRem(13),
    height: theme.footerHeight.lg,
    justifyContent: 'center',
    '& p': {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        margin: '0 16px',
      },
    },
  },
});

export default styles;
