import commonStyles from 'components/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
  root: {
    width: 1290,
    margin: '120px auto',
    [theme.breakpoints.down('md')]: {
      margin: '16px 16px 0 16px',
      width: 'auto',
    },
  },
  post: {
    display: 'flex',
    alignItems: 'center',
    height: 500,
    flex: '1 1 auto',
    border: '1px solid #E8E8E8',
    borderBottom: 'none',
    '&:last-child': {
      borderBottom: '1px solid #E8E8E8',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
      flexDirection: 'column',
      height: 'auto',
    },
  },
  text: {
    width: 470,
    padding: '0 110px 0 80px',
    '& h2': {
      color: theme.palette.black.main,
      margin: '0 0 30px',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(36),
    },
    '& p': {
      margin: '0 0 30px',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      padding: 16,
      '& a': {
        width: '100%',
        padding: 19,
      },
      '& h2': {
        fontSize: theme.typography.pxToRem(24),
        marginBottom: 16,
      },
      '& p': {
        marginBottom: 16,
      },
    },
  },
  image: {
    maxHeight: 500,
    height: 500,
    flex: '1 1 auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
    },
  },
});

export default styles;
