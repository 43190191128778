import commonStyles from 'components/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
  button: {
    margin: '1em 0',
    padding: '17px 46px',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export default styles;
