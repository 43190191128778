import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const date = new Date();
const copyrightDates = `2015-${date.getFullYear()}`;

const Footer = ({classes}) => (
  <>
    <section className={classes.root}>
      <p className={classes.copyright}>
        {`© ${copyrightDates} Server For Pos. All rights reserved.`}
      </p>
    </section>
  </>
);

Footer.propTypes = {
  classes: PropTypes.shape({
    copyright: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, {withTheme: true})(Footer);
