const styles = () => ({
  bgImage: {
    '& $img': {
      '&::before': {
        content: '',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'black',
        zIndex: 9,
      },
    },

    height: '100%',
    position: 'absolute !important',
    top: 0,
    width: '100%',
  },
  root: {
    position: 'relative',
  },
});

export default styles;
