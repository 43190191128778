import {createStore as reduxCreateStore} from 'redux';

const reducer = (state, action) => {
  if (action.type === 'OPEN') {
    return {
      ...state,
      open: true,
    };
  }
  if (action.type === 'CLOSE') {
    return {
      ...state,
      open: false,
    };
  }

  return state;
};

const initialState = {open: false};

const createStore = () => reduxCreateStore(reducer, initialState);

export default createStore;
