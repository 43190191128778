import commonStyles from 'components/commonStyles';

const styles = (theme) => ({
  ...commonStyles(theme),
  root: {
    maxWidth: 1130,
    display: 'grid',

    '& a:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.up('xs')]: {
      margin: 16,
      gridTemplateColumns: '1fr',
      gridGap: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('md')]: {
      margin: '120px auto',
      gridGap: '30px',
    },
  },
  post: {
    border: '1px solid #E8E8E8',
    maxWidth: 550,
    cursor: 'pointer',
  },
  title: {
    display: 'flex',
    marginBottom: 30,
    '& h2': {
      color: theme.palette.black.main,
      margin: '0 30px 0 0',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(36),
      lineHeight: '42px',
      maxWidth: '70%',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
      },
    },
    '& svg': {
      height: 42,
    },
  },
  text: {
    margin: '60px 80px',
    '& p': {
      margin: '0 0 30px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 16,
    },
  },
  image: {
    maxHeight: 300,
    height: 300,
    flex: '1 1 auto',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
});

export default styles;
