const styles = (theme) => ({
  '@global': {
    '*, *::before, *::after': {
      boxSizing: 'content-box',
    },
    '.vivid-orange': {
      color: theme.palette.vividOrange,
    },
    a: {
      '&:focus, &:hover': {
        textDecoration: 'underline',
      },
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },

    body: {
      fontFamily: theme.typography.fontFamily,
    },

    p: {
      fontSize: theme.typography.pxToRem(18),
      color: '#434343',
      margin: 0,
      fontWeight: '300',
      lineHeight: '150%',
    },

    h1: {
      fontFamily: 'IntroCondFree',
      fontSize: theme.typography.pxToRem(38),
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontWeight: 900,
      letterSpacing: 'normal',
      lineHeight: '0.66',
    },

    h3: {
      fontSize: theme.typography.pxToRem(36),
      fontWeight: '500',
    },

    h4: {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: '500',
    },

    h5: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: '500',
      margin: 0,
    },

    ul: {
      color: '#000',
      margin: 0,
    },

    ol: {
      color: '#000',
      margin: 0,
      '& ul': {
        marginTop: 8,
      },
    },

    li: {
      color: '#434343',
      marginBottom: 8,
      fontSize: theme.typography.pxToRem(18),
      fontWeight: '300',
      wordBreak: 'break-word',
    },

    hr: {
      border: '0',
      borderTop: `1px solid ${theme.palette.grey[400]}`,
      boxSizing: 'content-box',
      height: '0',
      margin: `${theme.spacing(1.5)}px 0`,
    },
  },

});

export default styles;
